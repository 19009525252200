@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  min-height: 100vh;
  background: rgb(63, 94, 251);
  background: radial-gradient(
    circle,
    rgba(63, 94, 251, 1) 0%,
    rgba(16, 31, 62, 1) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.glass {
  background: #fff;
  min-height: 80vh;
  width: 60%;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 1rem;
  z-index: 3;
  backdrop-filter: blur(2rem);
  display: flex;
}

h1 {
  color: #fff;
  margin-bottom: 0.5em;
  font-size: 2em;
}

.welcome {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  margin-left: 8em;
  margin-top: 2em;
}

.title {
  font-size: 2em;
  font-weight: normal;
  color: #4a4a4a;
  margin-bottom: 1em;
}

.subtitle {
  font-size: 1.5em;
  font-weight: lighter;
  color: #4a4a4a;
  margin-bottom: 1em;
}

.startbtn {
  padding: 1.5em;
  width: 50%;
  outline: none;
  background: #1a2d67;
  color: #fff;
  border: 2px solid #1a2d67;
  border-radius: 3px;
  margin-top: 3em;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
}
a {
  text-decoration: none;
  text-align: center;
}
.quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  margin: 0 auto;
}

.paginationButtons {
  height: 40px;
  list-style: none;
  display: flex;
  position: absolute;
  top: 400px;
}
.paginationButtons a {
  padding: 0.5em;
  margin: 0.5em;
  width: 50%;
  outline: none;
  background: #1a2d67;
  color: #fff;
  border: 2px solid #1a2d67;
  border-radius: 3px;
  margin-top: 3em;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
}
.paginationButtons a:hover {
  background: #bbc2dc;
  color: #1a2d67;
}
/* .paginationActive {
  background: #bbc2dc;
  color: #1a2d67;
}  */

.questionBody {
  min-width: 100%;
  max-width: 130%;
}

.questionTitle {
  display: flex;
  justify-content: space-between;
}
.questionTitle h3 {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
}

.questionMain {
  margin: 2em 0;
  border: 1px solid #1f337a;
  padding: 2em;
  border-radius: 5px;
  text-align: center;
  background: #1f337a;
  color: #fff;
}

.questionBtns {
  display: flex;
  justify-content: space-between;
}

.true {
  padding: 1em;
  width: 36%;
  outline: none;
  background: #004d40;
  color: #fff;
  border: 2px solid #004d40;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
}

.false {
  background: #b71c1c;
  padding: 1em;
  width: 36%;
  outline: none;
  /* background: #1a2d67; */
  color: #fff;
  border: 2px solid #b71c1c;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
}
